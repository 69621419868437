<template>

<com-content :blue="true" class="intro">

	<com-content :inner="true">

		<h1>Poweful, elegant, simple</h1>

		<p>A huge assortment of features and tools that all integrate into a single system</p>

	</com-content>

</com-content>
	
</template>

<script>

export default {

	components: {

		'com-content': () => import('./../common/Content')

	}

}

</script>

<style scoped>

.intro {
	padding: 130px 0px 60px 0px;
}

.is-mobile .intro {
	padding: 80px 0px 50px 0px;
}

.intro h1 {
	font-size: 28px;
    font-weight: 500;
    text-align: center;
	padding: 0px 10px;
    color: #fff;
}

.is-mobile .intro h1 {
	font-size: 24px;
}

.intro p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
    text-align: center;
	padding: 0px 10px;
	margin-top: 8px;
    color: #fff;
	margin-bottom: 40px;
}

.is-mobile .intro p {
	margin-bottom: 0px;
}

</style>